<template>
  <div class="min-h-screen flex relative lg:static surface-ground">
    <LayoutDefaultSideBarComponent />
    <div class="h-screen w-full max-h-screen min-h-screen flex flex-column relative flex-auto overflow-hidden">
      <LayoutDefaultTopBarComponent />
      <div class="flex flex-column flex-auto overflow-hidden">
        <LayoutDefaultBreadCrumbComponent />
        <div class="border-1 surface-border surface-section flex-auto inline-flex overflow-auto">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

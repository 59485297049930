<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useStore } from "@/stores/store";
import type { UnreadNotification, UnreadNotifications } from "~/interfaces/notifications/main";

const store = useStore();
const router = useRouter();
const opportunitiesStore = useOpportunitiesStore();
const usersStore = useUsersStore();
const notificationsStore = useNotificationsStore();
const { opportunity } = storeToRefs(opportunitiesStore);
const { authUserData } = storeToRefs(usersStore);

const unreadNotifications = ref<UnreadNotifications>();
const notifBellEl = ref<DOMTokenList>(null);
const menu = ref<DOMTokenList>(null);

const userMenuOptions = ref([
  {
    label: "Paramètres",
    items: [
      {
        label: "Notifications",
        icon: "pi pi-bell",
        command: () => {
          router.push("/settings/notifications");
        }
      }
    ]
  }
]);

const toggleNotificationsOverlay = (event) => {
  notifBellEl.value.toggle(event);
};

const userTrigram = computed(() => {
  return authUserData.value?.username.slice(0, 2).toUpperCase();
});

const fetchNotifications = async (notifIssuer = null) => {
  if (await notifIssuer && unreadNotifications.value) { // optimistic update
    unreadNotifications.value.data.find((notif: UnreadNotification) => notif.id === notifIssuer.id).readAt = notifIssuer.readAt;
  }

  unreadNotifications.value = await notificationsStore.getNotifications(true, 5);
};

const toggleSettingOverlay = (event) => {
  menu.value.toggle(event);
};

onMounted(async () => {
  await fetchNotifications();
});
</script>
<template>
  <div class="flex justify-content-between align-items-center px-2 surface-section relative lg:static border-bottom-1 surface-border">
    <div class="flex top-bar-left">
      <a
        v-ripple
        v-styleclass="{ selector: '#app-sidebar-5', enterClass: 'hidden', enterActiveClass: 'fadeinleft', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutleft', hideOnOutsideClick: true }"
        class="cursor-pointer block lg:hidden text-700 mr-3 mt-1 p-ripple"
      >
        <i class="pi pi-bars text-4xl" />
      </a>
      <!-- Commerce Buttons -->
      <div v-if="['commerce-sites', 'commerce-contacts'].includes($route.name) && !store.isMobile">
        <NuxtLink :to="{ name: 'commerce-sites' }">
          <Button
            icon="pi pi-building"
            :severity="$route.name === 'commerce-sites' ? 'warning' : 'secondary'"
            label="SITES"
            :class="['border-round-sm', $route.name === 'commerce-sites' ? 'text-white custom-warning-button' : '']"
            :outlined="!$route.name === 'commerce-sites'"
          />
        </NuxtLink>
        <NuxtLink :to="{ name: 'commerce-contacts' }">
          <Button
            icon="pi pi-users"
            :severity="$route.name === 'commerce-contacts' ? 'warning' : 'secondary'"
            label="CONTACTS"
            :class="['mx-2 border-round-sm', $route.name === 'commerce-contacts' ? 'text-white custom-warning-button' : '']"
            :outlined="!$route.name === 'commerce-contacts'"
          />
        </NuxtLink>
      </div>
      <!-- Opportunities Buttons -->
      <div v-if="$route.name.includes('opportunities-opportunityRefId') && !store.isMobile && opportunity">
        <!-- <NuxtLink:to="{ name: 'opportunities-opportunityRefId-preview', params: { id: opportunity.id } }"> -->
        <Button
          icon="pi pi-star"
          :severity="$route.params.id === opportunity.hashId ? 'warning' : 'secondary'"
          :label="'RAC #' + opportunity.reqId"
          :class="['m-2 p-2 border-round-sm w-10rem h-2rem text-sm font-bold', $route.params.id === opportunity.hashId ? 'text-white custom-warning-button' : '']"
          :outlined="$route.params.id !== opportunity.hashId"
        />
        <!-- </NuxtLink> -->
        <!-- TODO loop through related opportunities -->
      </div>
      <span class="p-input-icon-left align-self-center hidden lg:block">
        <i class="pi pi-search text-xl" />
        <InputText
          type="text"
          class="border-none w-10rem sm:w-20rem text-xs ml-2 mt-1"
          placeholder="Numéro dossier, Nom client, Numéro de téléphone..."
          disabled
        />
      </span>
    </div>
    <div class="ml-auto mr-3">
      <div class="bg-orange-500 p-1 text-sm border-round-md text-white">
        {{ useRuntimeConfig().public.APP_VERSION }}
      </div>
    </div>
    <a
      v-ripple
      v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'fadein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
      class="cursor-pointer block lg:hidden text-700 p-ripple"
    >
      <i class="pi pi-ellipsis-v text-2xl" />
    </a>
    <ul
      class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row
                surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static"
    >
      <li class="disabled">
        <a
          v-ripple
          class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round
                        transition-duration-150 transition-colors p-ripple"
        >
          <i class="pi pi-phone text-base text-green-500 lg:text-2xl mr-2 lg:mr-0" />
          <span class="block lg:hidden font-medium">Phone</span>
        </a>
      </li>
      <li class="disabled">
        <a
          v-ripple
          class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round
                        transition-duration-150 transition-colors p-ripple"
        >
          <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0" />
          <span class="block lg:hidden font-medium">Inbox</span>
        </a>
      </li>
      <li class="disabled">
        <a
          class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round
                        transition-duration-150 transition-colors"
          @click="toggleNotificationsOverlay"
        >
          <i v-if="unreadNotifications?.meta?.totalItems" v-badge.danger="unreadNotifications?.meta?.totalItems" class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0" />
          <i v-else class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0" />
          <span class="block lg:hidden font-medium">Notifications</span>
        </a>

        <OverlayPanel ref="notifBellEl">
          <div style="width: min(90vw, 400px);">
            <div class="flex justify-content-between align-items-center mt-1 mb-3">
              <div class="flex align-items-center gap-1">
                <span class="text-2xl font-semibold">Notifications non lues</span>
              </div>
              <span>{{ unreadNotifications.meta.totalItems }} non lues</span>
            </div>

            <div v-if="unreadNotifications.data.length" class="flex flex-column">
              <NotificationsCard v-for="notification in unreadNotifications.data" :key="notification.id" :notification="notification" @refresh="fetchNotifications(notification)" />
            </div>

            <div v-else class="text-center py-4">
              Aucune notification non lue
            </div>

            <NuxtLink to="/notifications" class="block text-center pt-3">
              <Button severity="secondary" outlined>
                Voir toutes les notifications
              </Button>
            </NuxtLink>
          </div>
        </OverlayPanel>
      </li>
      <li class="border-top-1 surface-border lg:border-top-none disabled">
        <a
          v-ripple
          class="flex p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round
                        transition-duration-150 transition-colors p-ripple"
          @click="toggleSettingOverlay"
        >
          <Avatar :label="userTrigram" class="p-3 cursor-pointer" shape="circle" />
          <Menu ref="menu" :model="userMenuOptions" :popup="true" />
          <div class="block lg:hidden">
            <div class="text-900 font-medium">Alban Tor</div>
            <span class="text-600 font-medium text-sm">Marketing Specialist</span>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

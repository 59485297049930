<script setup lang="ts">
import { useRoute } from "vue-router";
const route = useRoute();
const pathPieces = ref([]);

onMounted(() => {
  // At component loading
  if (route.fullPath.split("?")[0].split("/").filter(p => p !== "").reverse().length > 0) {
    pathPieces.value = route.fullPath.split("?")[0].split("/").filter(p => p !== "");
  } else {
    pathPieces.value = [];
  }
});

const computedBreadCrumbName = computed(() => {
  return (pathPart: String) => {
    let computedName = "";
    pathPart.split("_").forEach((pp) => {
      if (computedName !== "") { computedName += " "; }
      computedName += pp[0].toUpperCase() + pp.slice(1);
    });
    return computedName;
  };
});

// When route is updated
watch(() => route.fullPath, (fullPath) => {
  if (fullPath.split("?")[0].split("/").filter(p => p !== "").reverse().length > 0) {
    pathPieces.value = fullPath.split("?")[0].split("/").filter(p => p !== "");
  } else {
    pathPieces.value = [];
  }
});

// function goToStep (pathPiece) {
//   const matchingId = route.fullPath.indexOf(pathPiece);
//   router.push(route.fullPath.slice(0, matchingId + pathPiece.length));
// }
</script>

<template>
  <div>
    <ul class="list-none p-0 m-0 surface-card flex flex-auto overflow-auto font-medium line-height-2 border-x-1 surface-border" style="height: 32px;">
      <li class="relative p-2">
        <a class="cursor-pointer">
          <!-- UNCOMMENT WHEN READY -->
          <!-- <i class="pi pi-home text-orange-500" @click="router.push('/')" /> -->
          <i class="pi pi-home text-orange-500" />
        </a>
      </li>
      <li v-for="(pathPiece, pathKey) in pathPieces" :key="pathKey" class="relative p-2">
        <div class="absolute left-0 top-0 z-1" style="border-left: 12px solid var(--surface-card); border-top: 16px solid transparent; border-bottom: 16px solid transparent; width: 0; height: 0" />
        <!-- UNCOMMENT WHEN READY -->
        <!-- <a
          :class="[
            'cursor-pointer text-orange-500 pl-4 white-space-nowrap',
            pathPiece === pathPieces[pathPieces.length-1] ? 'text-black-alpha-90' : ''
          ]"
          @click="goToStep(pathPiece)"
        > -->
        <a
          :class="[
            'cursor-pointer text-orange-500 pl-4 white-space-nowrap',
            pathPiece === pathPieces[pathPieces.length-1] ? 'text-black-alpha-90' : ''
          ]"
        >
          {{ computedBreadCrumbName(pathPiece) }}
        </a>
        <div class="absolute top-0" style="left: 1px; border-left: 12px solid var(--surface-border); border-top: 16px solid transparent; border-bottom: 16px solid transparent; width: 0; height: 0" />
      </li>
    </ul>
  </div>
</template>
